import { getCommonParamsOptions } from '@/utils/common.js'

import Validator from '@/validators'
import { isNumber } from '@/utils/validate.js'

var paramType = ''

const paramTypeValidator = async (rule, value, callback) => {
  paramType = value
  callback()
}

const defaultValueValidator = (rule, value, callback) => {
  if (value) {
    if (paramType === '01') {
      return isNumber(rule, value, callback)
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.param-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'paramType',
      type: 'select',
      label: 'param.param-value-type',
      isResolveGlobalParams: true,
      isLinkageOptions: true,
      options: [],
      handler: () => getCommonParamsOptions('value_type')
    },
    {
      field: 'defaultValue',
      type: 'input',
      label: 'param.default-value',
      otherOptions: {
        maxlength: 500
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: Validator.minLength, length: 1 },
      { validator: Validator.maxLength, length: 80 }
    ],
    defaultValue: [{ validator: defaultValueValidator, trigger: 'change' }],
    status: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    paramType: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      {
        validator: paramTypeValidator,
        trigger: 'change'
      }
    ]
  }
}
